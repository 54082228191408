import { useTranslation } from "react-i18next";

const Featurette = ({
  title,
  description,
  orientation,
  buttonLabel,
  buttonLink,
  featImage,
}) => {
  const { t } = useTranslation();
  const button = buttonLabel ? (
    <a
      href={buttonLink}
      className="btn btn-primary btn-lg px-4 me-sm-3 fw-bold"
    >
      {t(buttonLabel)}
    </a>
  ) : (
    <></>
  );

  const imgClass =
    !orientation || orientation === "left" ? "col-md-5 order-md-2" : "col-md-5";

  return (
    <div className="row featurette py-3">
      <div className={imgClass}>
        <img
          src={featImage}
          alt="featurette-logo"
          className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
        />
      </div>

      <div className="col-md-7">
        <a name="collaboration" href="http://google.it" className="anchor">
          {" "}
        </a>
        <h2 className="featurette-heading">{t(title)}</h2>
        <p className="lead">{description}</p>
        {button}
      </div>
    </div>
  );
};

export default Featurette;
