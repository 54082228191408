import logo from "../assets/img/logo-orizzontale.svg";
import english from "../assets/img/english.svg";
import italian from "../assets/img/italian.svg";
import german from "../assets/img/german.svg";
import { useTranslation } from "react-i18next";

const getLanguageFlag = (lang) => {
  const baseLanguage = lang?.split("-")[0];
  const mapping = {
    it: italian,
    en: english,
    de: german,
  };
  return mapping[baseLanguage];
};

const Header = () => {
  const { t, i18n } = useTranslation();

  return (
    <header className="d-flex flex-wrap justify-content-center pt-3 px-5 mb-4 border-bottom fixed-top bg-light">
      <a
        href="#logo"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
      >
        <img src={logo} alt="logo" className="logo" />
      </a>

      <div
        className="nav nav-tabs justify-content-center"
        id="menu"
        role="tablist"
      >
        <a href="#events">
          <button className="nav-link" type="button">
            {t("events")}
          </button>
        </a>
        <a href="#help">
          <button className="nav-link" type="button">
            {t("help")}
          </button>
        </a>
        <a href="#collaboration">
          <button className="nav-link" type="button">
            {t("collaborate")}
          </button>
        </a>
        {/* <a href="#lang" onClick={() => changeLanguage()}>
          <img src={langImg} alt="language" className="language" />
        </a> */}
        <div className="dropdown">
          <button
            className="btn dropdown-toggle"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              alt="flag"
              src={getLanguageFlag(i18n.language)}
              className="language"
            />
          </button>

          <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <button
                onClick={() => i18n.changeLanguage("it-it")}
                className="dropdown-item"
              >
                <img alt="flag" src={italian} className="language" />
                &nbsp; Italiano
              </button>
            </li>
            <li>
              <button
                onClick={() => i18n.changeLanguage("en-gb")}
                className="dropdown-item"
              >
                <img alt="flag" src={english} className="language" />
                &nbsp; English
              </button>
            </li>
            <li>
              <button
                onClick={() => i18n.changeLanguage("de-de")}
                className="dropdown-item"
              >
                <img alt="flag" src={german} className="language" />
                &nbsp; Deutsch
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
