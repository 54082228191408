import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-hero text-secondary px-4 py-5 text-center">
      <div className="py-5">
        <a name="help" className="anchor"></a>
        <h1 className="fw-bold text-white">{t("need help")}</h1>
        <div className="col-lg-6 mx-auto">
          <p className="fs-5 mb-4 text-white">{t("discover more")}</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <a href={t("freshdesk help link")}>
              <button
                type="button"
                className="btn btn-focus btn-lg px-4 me-sm-3 fw-bold"
              >
                {t("visit helpdesk")}
              </button>
            </a>
            <a href="https://endu.freshdesk.com/it/support/tickets/new">
              <button
                type="button"
                className="btn btn-outline-light btn-lg px-4"
              >
                {t("contact us")}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
