import { useTranslation } from "react-i18next";
import Thumbnail from "./Thumbnail";

const TabbedHero = ({
  activeTab,
  setActiveTab,
  eventList,
  showLoadMore,
  bumpNumberOfEvents,
}) => {
  const navLastClass = "nav-link " + (activeTab === "past" ? "active" : "");
  const navNextClass = "nav-link " + (activeTab === "next" ? "active" : "");

  const tabLastClass = "tab-pane fade show";
  const tabNextClass = "tab-pane fade active show";

  const { t, i18n } = useTranslation();

  const setActiveTabAndAnchor = (active) => {
    window.location.href = `#${active}`;
    setActiveTab(active);
  };

  const loadMore = (
    <div className="col-lg-12 center mx-auto text-center pt-5">
      <a
        href="#0"
        onClick={() => bumpNumberOfEvents()}
        className="btn btn-focus btn-lg px-4 me-sm-3 fw-bold"
      >
        {t("load more")}
      </a>
    </div>
  );

  return (
    <>
      <section className="text-center container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <a className="anchor" href="http://google.it" name="events">
              anchor
            </a>
            <h1 className="fw-bold">{t("endupix events")}</h1>
            <p className="lead">{t("access your photos")}</p>
            <nav>
              <div className="nav nav-tabs justify-content-center">
                <button
                  className={navLastClass}
                  onClick={() => setActiveTabAndAnchor("past")}
                  type="button"
                >
                  {t("past events")}
                </button>
                <button
                  className={navNextClass}
                  onClick={() => setActiveTabAndAnchor("next")}
                  type="button"
                >
                  {t("next events")}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </section>
      <div className="tab-content">
        <div className={tabLastClass} aria-labelledby="nav-prev-tab"></div>
        <div className={tabNextClass} aria-labelledby="nav-next-tab"></div>
        <div className="album py-5 bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 g-3">
              {eventList.map((event, index) => (
                <div key={index} className="col">
                  <Thumbnail
                    title={event.name}
                    city={event.city}
                    province={event.province}
                    beginDate={event.beginDate}
                    endDate={event.endDate}
                    link={event.link}
                    logoLink={event.logoLink}
                    disciplines={
                      i18n.language === "it"
                        ? event.disciplinesIt
                        : event.disciplinesEn
                    }
                    photographer={event.photographer}
                    oldEventList={event.oldEventList}
                    time={event.time}
                  />
                </div>
              ))}
            </div>
            {showLoadMore ? loadMore : <></>}
          </div>
        </div>
      </div>
    </>
  );
};

export default TabbedHero;
