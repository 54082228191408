import "bootstrap/dist/css/bootstrap.css";
import { Trans, useTranslation } from "react-i18next";
import "typeface-roboto";
import "./assets/endu.css";
import feat1 from "./assets/img/feature-1.svg";
import feat2 from "./assets/img/feature-2.svg";
import feat3 from "./assets/img/feature-3.svg";
import feat4 from "./assets/img/feature-4.svg";
import PixEventList from "./business/PixEventList";
import Carousel from "./components/Carousel";
import Featurette from "./components/Featurette";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";

function App() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Header />
      <main>
        <Carousel />

        <PixEventList />

        <Hero />

        <div className="container marketing py-5">
        <Featurette
            title="face recognition"
            description={
              <Trans t={t} i18nKey="fr technology">
                0<strong>1</strong>2<strong>3</strong>4
              </Trans>
            }
            buttonLabel="discover"
            buttonLink="mailto:pix@endu.net"
            orientation="right"
            featImage={feat4}
          />
          <hr />

          <Featurette
            title="photography is your passion"
            description={
              <Trans t={t} i18nKey="kind of photographer">
                0<strong>1</strong>2<strong>3</strong>4
              </Trans>
            }
            buttonLabel="shoot with us"
            buttonLink="mailto:pix@endu.net"
            orientation="left"
            featImage={feat1}
          />
          <hr />

          <Featurette
            title="are you manager"
            description={
              <Trans t={t} i18nKey="organize events">
                0<strong>1</strong>2
              </Trans>
            }
            buttonLabel="collaborate with us"
            buttonLink="mailto:pix@endu.net"
            orientation="right"
            featImage={feat2}
          />
          <hr />

          <Featurette
            title={"guided by passion"}
            description={
              <Trans t={t} i18nKey="adrenaline">
                0<strong>1</strong>2<strong>3</strong>4
              </Trans>
            }
            buttonLabel="promote a brand"
            buttonLink="mailto:pix@endu.net"
            orientation="left"
            featImage={feat3}
          />
        </div>

        {/* <Logos /> */}
        <Footer />
      </main>
    </div>
  );
}

export default App;
