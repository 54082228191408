import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const linkPrivacy =
    (i18n.language === "it" || i18n.language === "it-it")
      ? "https://ftp-endu-net.s3.amazonaws.com/pdf/informativa_endupix_2022v2_it.pdf"
      : "https://ftp-endu-net.s3.amazonaws.com/pdf/Informativa_endupix_2022v2_en.pdf";
  return (
    <footer className="pt-3 pb-1 px-5">
      <p className="float-end">
        <a href="#">{t("back on top")}</a>
      </p>
      <p>
        <a href="https://www.endu.net/">ENDU</a> - Engagigo srl - {t("VAT")}
        02804190342 - <a href="https://www.endu.net/terms">T&C</a> &middot;{" "}
        <a href="https://www.endu.net/privacy">Privacy Policy</a> &middot;{" "}
        <a href="https://www.endu.net/cookies">Cookie Policy</a> &middot;{" "}
        <a href={linkPrivacy}>ENDUpix Privacy Policy</a>
      </p>
    </footer>
  );
};

export default Footer;
