import "bootstrap/dist/js/bootstrap.min.js";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation();

  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="container">
            <div className="carousel-caption">
              <h1>{t("photos now")}</h1>
              <p>{t("no waiting")}</p>
              <p>
                <a className="btn btn-lg btn-focus" href="#events">
                  {t("watch")}
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="carousel-item">
          <div className="container">
            <div className="carousel-caption">
              <h1>{t("we collaborate")}</h1>
              <p>{t("brands events photographers")}</p>
              <p>
                <a className="btn btn-lg btn-focus" href="#collaboration">
                  {t("discover")}
                </a>
              </p>
            </div>
          </div>
        </div>
        
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
