import axios from "axios";
import { useState, useEffect } from "react";
import TabbedHero from "../components/TabbedHero";

const dummyEvent = {
  beginDate: "2021-01-01T04:00:00Z",
  name: null,
  photographer: "ENDUpix",
  city: null,
  province: "ND",
  link: "http://www.endupix.net",
  logoLink:
    "https://ftp-endu-net.s3.amazonaws.com/progetti/ENDUpix/img/event-logo.png",
  disciplinesIt: ["Riprova più tardi"],
  disciplinesEn: ["Try again later"],
  oldEventList: [],
};

const dummyEventList = [dummyEvent, dummyEvent, dummyEvent];

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const PixEventList = () => {
  // type: last or next
  const [tabShown, setTabShown] = useState("past");
  const [numberOfEventsShown, setNumberOfEventsShown] = useState(12);
  const [eventsShown, setEventsShown] = useState(dummyEventList);
  const [totalNumberOfEvents, setTotalNumberOfEvents] = useState(0);

  const setActiveTab = (active) => {
    setTabShown(active);
    setNumberOfEventsShown(12);
  };

  useEffect(() => {
    const loadPageData = async () => {
      try {
        const { data } = await axios.get(
          `${window._env_.REACT_PUBLIC_ENDPOINT}/pixsite/${tabShown}eventlist?limit=${numberOfEventsShown}`
        );
        console.info("Fetched eventList {}", data);
        setEventsShown(
          data.content.map((content) => ({ ...content, time: tabShown }))
        );
        setTotalNumberOfEvents(data.totalElements);
        const url = window.location.href;
        if (url.includes("#collaboration")) {
          sleep(700).then(() => {
            console.log(url);
            window.location.href = url;
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadPageData();
  }, [tabShown, numberOfEventsShown]);

  const bumpNumberOfEvents = () => {
    setNumberOfEventsShown(numberOfEventsShown + 6);
  };

  return (
    <TabbedHero
      activeTab={tabShown}
      setActiveTab={setActiveTab}
      eventList={eventsShown}
      showLoadMore={
        numberOfEventsShown < 30 && numberOfEventsShown < totalNumberOfEvents
      }
      bumpNumberOfEvents={bumpNumberOfEvents}
    />
  );
};

export default PixEventList;
