import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "it-it",
    react: {
      bindI18n: "loaded languageChanged",
      bindI18nStore: "added",
      useSuspense: false,
    },
    //debug: process.env.REACT_APP_DEBUG,
    lowerCaseLng: true,
    cleanCode: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
