import { useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { InfoIcon } from "./InfoIcon";

const Thumbnail = ({
  title,
  city,
  province,
  beginDate,
  endDate,
  disciplines,
  link,
  logoLink,
  photographer,
  oldEventList,
  time,
}) => {
  const { t } = useTranslation();

  const errorTitle = title ? title : t("title error");
  const errorCity = city ? city : t("city error");

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const trimSize = 50;
  const trimmedTitle =
    errorTitle.length > trimSize
      ? errorTitle.substring(0, trimSize - 3) + "..."
      : errorTitle;

  const displayableBeginDate = new Date(beginDate)
    .toLocaleString()
    .split(",")[0];
  const displayableEndDate = new Date(endDate).toLocaleString().split(",")[0];
  const displayableDisciplines = disciplines
    .map((a) => <span>{a}</span>)
    .reduce((a, b) => (
      <>
        {a}
        <span>|</span>
        {b}
      </>
    ));
  let displayableOldEventList = <></>;
  if (oldEventList && oldEventList.length > 0) {
    const linkList = oldEventList
      .map((event, index) => (
        <a key={index} href={event.link} className="">
          {event.year}
        </a>
      ))
      .reduce((a, b) => (
        <>
          {a} • {b}
        </>
      ));
    displayableOldEventList = (
      <p className="date pt-4">
        {t("editions")}: {linkList}{" "}
      </p>
    );
  }

  return (
    <div className="card text-center">
      <div className="cover">
        <a href={`${link}`}>
          <img className="logo" alt="logo" src={logoLink} />
        </a>
        <p className="category pt-2">{displayableDisciplines}</p>
        <a href={`${link}`}>
          <h3>{trimmedTitle}</h3>
        </a>
      </div>
      <div className="card-body">
        <p className="date">
          {displayableBeginDate}
          {displayableBeginDate === displayableEndDate
            ? ""
            : " - " + displayableEndDate}
        </p>
        <p className="date">
          {errorCity} ({province})
        </p>
        <a
          href={`${link}`}
          className="btn btn-primary btn-md px-4 me-sm-3 fw-bold mt-2"
        >
          {time === "next" ? t("more info") : t("photos check")}
        </a>
        {displayableOldEventList}
      </div>
      <div className="card-photoby">
        <hr />
        <div className="d-flex mb-3 justify-content-center align-items-baseline">
          <p className="photographer mb-0">
            {t("photos from")} <strong>{photographer.nome}</strong>
          </p>
          <span className="mx-1" ref={ref}>
            <span style={{ display: "inline-block" }} onClick={handleClick}>
              <InfoIcon />
            </span>
            <Overlay
              show={show}
              target={target}
              placement="left"
              container={ref}
              containerPadding={20}
            >
              <Popover id="popover-contained">
                <Popover.Header>
                  <strong>{photographer.nome}</strong>
                </Popover.Header>
                <Popover.Body>
                  <table className="table table-sm card-table">
                    <tbody>
                      <tr>
                        <th>{t("businessname")}:</th>
                        <td>{photographer.ragioneSociale ?? "-"}</td>
                      </tr>
                      <tr>
                        <th>{t("address")}:</th>
                        <td>
                          {photographer.indirizzo &&
                          photographer.cap &&
                          photographer.citta
                            ? `${photographer.indirizzo} ${photographer.cap} ${photographer.citta}`
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("email")}:</th>
                        <td>
                          {photographer.email ? (
                            <a href={`mailto:${photographer.email}`}>
                              {photographer.email}
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Popover.Body>
              </Popover>
            </Overlay>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Thumbnail;
